import React from 'react';
import logo from './red-wine.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} />
      </header>
      <p>#soon</p>
    </div>
  );
}

export default App;
